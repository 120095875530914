
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class ErrorByStatus extends Vue {
  // computed // ***
  get status() {
    return this.$route.params.status
  }
}
